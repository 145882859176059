import { render, staticRenderFns } from "./videoReportMatter.vue?vue&type=template&id=fbc6db6a&scoped=true&"
import script from "./videoReportMatter.vue?vue&type=script&lang=js&"
export * from "./videoReportMatter.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "fbc6db6a",
  null
  
)

export default component.exports